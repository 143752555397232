<template>
    <div>
            <v-card elevation="2"
                    outlined
                    tile
                    class="py-5 px-5 my-2">
                <v-row>
                    <v-col class="col-md-10">
                        <v-text-field
                            label="Nombre Segmento"
                            placeholder="agrega un nombre para el segmento"
                            v-model="segment.name_architecture"
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-md-2">
                        <v-btn
                            class="ma-2"
                            text
                            icon
                            color="indigo"
                            @click="changeSegment()"
                        >
                            <v-icon> mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col>
                        <br>
                        <quill-editor
                                ref="myQuillEditor"
                                v-model="segment.description"
                                :options="editorOption"
                        /><br>
                        <!-- <v-textarea
                            outlined
                            name="input-7-4"
                            label="Descripción"
                            v-model="segment.description"
                        ></v-textarea> -->
                        <label class="mr-5">Seleccionar un color</label>
                        <input type="color" v-model="segment.color" />
                    </v-col>
                </v-row>
           
            </v-card>
         <v-row v-for="(item, index) in segment.architecture_tag" :key="item.id">
            <v-col >
                <v-card
                    elevation="2"
                    outlined
                    tile
                    class="py-8 px-6"
                >
                <v-row>
                    <v-col class="col-md-5">
                        <h2>{{item.tag}}</h2>
                    </v-col>
                    <v-col class="col-md-5">
                        <v-text-field :id="'name'+index"
                            label="Nombre de la categoria"
                            placeholder="Placeholder"
                            outlined
                            v-model="item.tag"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-md-2">
                        <v-btn
                            class="ma-2"
                            text
                            icon
                            color="indigo"
                            @click="categoriesUpdate(index, item.id)"
                        >
                            <v-icon> mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                     <v-col class="col-md-5">
                        <div v-html="item.description"></div>
                    </v-col>
                    <v-col class="col-md-7">
                        <quill-editor
                                :ref="'description'+index"
                                v-model="item.description"
                                :options="editorOption"
                        /><br>
                        
                    </v-col>
                </v-row>
                </v-card>
            </v-col>
        </v-row>
        
    </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data(){
     
        return{
            segment:{
                name_architecture:'',
                description:'',
                id:'',
                color: ''
            },
            id : this.$route.params.id,
            editorOption: { 
            }
        }
    },

    mixins:[misMixins],

    methods:{
        getSegmentInfo(){
            
            this.$http.get('architectures/'+this.id).then(response => {
                this.segment=response.data
                console.log('Segmento',this.segment)
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
            })
        },
        changeSegment(){

             this.LoadingOpen();

             this.$http.put('architectures/'+this.id,{
                 data:{
                     attributes:{
                         name_architecture:this.segment.name_architecture,
                         description:this.segment.description,
                         color: this.segment.color
                     }
                 }
             },{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {

                this.LoadingClose();
                        
                let me = this
                me.$swal({
                    title: "Actualizado",
                    text: "segmento actualizado con éxito",
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                        }).then((result) => {
                            if (result.value) {
                                    me.$router.push({name:'Segments'})
                                }
                    })

                 }).catch((error) => {
                     this.LoadingClose();
                    
                     this.ErrorAlert('Error al actualizar el segmento')
                });
           
        },
        categoriesUpdate(idx, id){
            const name = this.segment.architecture_tag[idx].tag
            const description = this.segment.architecture_tag[idx].description
            this.$http.put('architecture_tags/'+id,{
                 data:{
                     attributes:{
                         tag:name,
                         description:description
                     }
                 }
             },{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                // this.segment=response.data
                this.$router.push({ name: 'Segments'});
            }).catch(error => {
                //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
            })
        }
    },
    created(){
        this.getSegmentInfo()
    }
}
</script>
